import { Routes } from '@angular/router';
import { authGuard } from './core/guards/auth.guard';
import { stepperGuard } from './core/guards/stepper.guard';
import { projectResolver } from './core/resolvers/project.resolver';
import { repriseProjectResolver } from './core/resolvers/reprise-project.resolver';
import { UnauthorizedComponent } from './core/unauthorized/unauthorized.component';
import { RepriseProjetComponent } from './view/reprise-projet/reprise-projet.component';

export class Routing {
  static PROJECT: string = 'project';
  static NEW_PROJECT: string = 'new/project';
  static INFO: string = 'infos';
  static TARIFS: string = 'tarifs';
  static SOUSCRIPTION: string = 'souscription';
  static REPRISEPROJET: string = 'reprise-projet';
  static DEVISSAUVEGARDE: string = 'devis-sauvegarde';
  static CONFIRMATIONSOUSCRIPTION: string = 'confirmation-souscription';
  static UNAUTHORIZED: string = '403';
  static NOT_FOUND: string = '404';
}
export const routes: Routes = [
  {
    path: '',
    redirectTo: Routing.PROJECT,
    pathMatch: 'full'
  },
  {
    path: Routing.PROJECT,
    resolve: {
      queryParams: projectResolver
    },

    loadComponent: () =>
      import('./view/project/project.component').then((c) => c.ProjectComponent)
  },
  {
    path: Routing.NEW_PROJECT,
    canActivate: [authGuard],
    loadComponent: () =>
      import('./view/project/project.component').then((c) => c.ProjectComponent)
  },
  {
    path: Routing.INFO,
    canActivate: [stepperGuard],
    loadComponent: () =>
      import('./view/infos/infos.component').then((c) => c.InfosComponent)
  },
  {
    path: Routing.TARIFS,
    canActivate: [stepperGuard],
    loadComponent: () =>
      import('./view/tarifications/tarifications.component').then(
        (c) => c.TarificationsComponent
      )
  },
  {
    path: Routing.SOUSCRIPTION,
    canActivate: [stepperGuard],
    loadComponent: () =>
      import('./view/souscription/souscription.component').then(
        (c) => c.SouscriptionComponent
      )
  },
  {
    path: Routing.DEVISSAUVEGARDE,
    loadComponent: () =>
      import('./view/devis-sauvegarde/devis-sauvegarde.component').then(
        (c) => c.DevisSauvegardeComponent
      ),
    data: { type: 'sauvegarde' }
  },
  {
    path: Routing.CONFIRMATIONSOUSCRIPTION,
    loadComponent: () =>
      import('./view/devis-sauvegarde/devis-sauvegarde.component').then(
        (c) => c.DevisSauvegardeComponent
      ),
    data: { type: 'subscription' }
  },
  {
    path: Routing.REPRISEPROJET,
    resolve: {
      queryParams: repriseProjectResolver
    },
    component: RepriseProjetComponent
  },
  {
    path: Routing.UNAUTHORIZED,
    component: UnauthorizedComponent
  },
  {
    path: '**',
    component: UnauthorizedComponent
  }
];
