import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PoliciesMode } from '../../shared/helpers/client.utils';
import {
  AcsMyProjectDataModel,
  AcsProjectModel,
  BusinessSectors,
  SaveExpatProjectModel
} from '../../shared/model/acs-project.model';
import { customListStore } from '../../shared/services/custom-list.store';
import { GlobalStore } from '../../shared/services/global.store';
import { LanguageStore } from '../../shared/services/language.store';
import { ApiConfiguration } from '../api/api-configuration';
import { ExpatClient } from '../api/models/expat-client';
import { ExpatPricing } from '../api/models/expat-pricing';
import { ExpatProject } from '../api/models/expat-project';
import { SuccessResponse } from '../api/models/success-response';
import { TokenResponse } from '../api/models/token-response';
import { TokenUuid } from '../api/models/token-uuid';
import { ApiService } from '../api/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class AcsApiService extends ApiConfiguration {
  readonly #apiService: ApiService = inject(ApiService);
  readonly #globalStore: GlobalStore = inject(GlobalStore);
  readonly #key: string = 'token';
  readonly #customListStore = inject(customListStore);
  readonly #languageStore: LanguageStore = inject(LanguageStore);

  /**
   * Envoie le mail avec le code d'activation
   * @Info:En dev le code est 123456
   * @param uuid
   */
  public getAccessCode(uuid: string): Observable<SuccessResponse> {
    return this.#apiService.getRequestAccess({
      uuid: uuid,
      _locale: this.#languageStore.currentLanguageCode(),
      Parameters: {
        mode: 'expat_external_producer',
        entity: PoliciesMode.POLICIES
      }
    });
  }

  /**
   * Post du code recu par mail et de l'uuid du broker
   * @param uuid string uuid du broker
   * @param code string code de verif a 6 digits
   */
  public requestAccessToken(
    uuid: string,
    code: string
  ): Observable<TokenResponse> {
    return this.#apiService.postSendVerificationCode({
      _locale: this.#languageStore.currentLanguageCode(),
      body: {
        uuid: uuid,
        code: code
      }
    });
  }

  /**
   * Retourne la liste des pays disponible depuis l'api
   */
  public getAcsProjectDataModel(): Observable<AcsMyProjectDataModel> {
    return this.#customListStore
      .getSessionStore(this.#languageStore.currentLanguageCode())
      .pipe(
        map((res) => {
          return {
            minimum_subscription_period: res?.minimum_subscription_period,
            nationality_countries: res?.nationality_countries,
            expatriation_countries: res?.expatriation_countries,
            acs_cfe_allowed_nationalities: res?.acs_cfe_allowed_nationalities,
          };
        })
      );
  }

  /**
   * Retourne la liste des secteur de business
   */
  public getBusinessSector(): Observable<BusinessSectors> {
    return this.#customListStore
      .getSessionStore(this.#languageStore.currentLanguageCode())
      .pipe(
        map((res) => {
          return {
            business_sectors: res?.business_sectors
          };
        })
      );
  }

  /**
   * Post du projet pour recuperer le tarificateur
   * @param project AcsProjectModel
   */
  public postPricing(project: AcsProjectModel): Observable<ExpatPricing> {
    return this.#apiService
      .postExpatTarificationCalculate({
        _locale: this.#languageStore.currentLanguageCode(),
        body: {
          pricing: project.pricing,
          beneficiaries: project.beneficiaries,
          product: project.product
        }
      })
      .pipe(map((res) => res.data));
  }

  /**
   * Sauvegarde du project
   * @param project
   * @return : Token du projet
   */
  public saveProject(project: SaveExpatProjectModel): Observable<TokenUuid> {
    return this.#apiService
      .postExpatTarificationSaveProject({
        _locale: this.#languageStore.currentLanguageCode(),
        token: project.token,
        body: {
          pricing: project.pricing,
          beneficiaries: project.beneficiaries,
          client: project.client,
          producer_code: project.producer_code,
          token: project.token,
          mode: this.#globalStore.$acsMode() as
            | 'expat_internal'
            | 'expat_external_client'
            | 'expat_external_producer',
          visitor_token: this.#globalStore.getVisitorParam()?.visitor_token,
          fingerprint: this.#globalStore.getVisitorParam()?.fingerprint,
          subscription: project.subscription,
          product: project.product as 'expat_eur' | 'expat_usd' | undefined
        }
      })
      .pipe(map((res) => res.data));
  }

  /**
   * Recupere le project sauvegarder par son uuid
   * @param token
   * @param uuid string uuid du projet
   * @info : Le parametre token ne devrait plus etre dans cette methode
   */
  public getProjectByUuid(
    uuid: string,
    token: string
  ): Observable<ExpatProject> {
    return this.#apiService
      .getGetExpatTarificationProject({
        _locale: this.#languageStore.currentLanguageCode(),
        token: token,
        uuid: uuid
      })
      .pipe(map((res) => res.data));
  }

  /**
   * Edition du project
   * @param token
   * @param uuid
   * @param project
   */
  public editExpatProject(
    token: string,
    uuid: string,
    project: SaveExpatProjectModel
  ): Observable<TokenUuid> {
    return this.#apiService
      .putExpatTarificationUpdateProject({
        _locale: this.#languageStore.currentLanguageCode(),
        token: token,
        uuid: uuid,
        body: {
          pricing: project.pricing,
          beneficiaries: project.beneficiaries,
          client: project.client,
          producer_code: project.producer_code,
          mode: this.#globalStore.$acsMode() as
            | 'expat_internal'
            | 'expat_external_client'
            | 'expat_external_producer',
          visitor_token: this.#globalStore.getVisitorParam()?.visitor_token,
          fingerprint: this.#globalStore.getVisitorParam()?.fingerprint,
          subscription: project.subscription,
          product: project.product as 'expat_eur' | 'expat_usd' | undefined
        }
      })
      .pipe(map((res) => res.data));
  }

  /**
   * Recupere les info de l'expat avec son uuid
   * @param uuid
   * @param token
   * @info : Le parametre token ne devrait plus etre dans cette methode
   */
  public getExpatInfo(uuid: string, token: string): Observable<ExpatClient> {
    return this.#apiService
      .getExpatClientGet({
        _locale: this.#languageStore.currentLanguageCode(),
        uuid: uuid,
        token: token
      })
      .pipe(map((res) => res.data));
  }

  public setToken(token: string): void {
    sessionStorage.setItem(this.#key, token);
  }
  public getToken(): string | null {
    return sessionStorage.getItem(this.#key);
  }

  public verifyToken(
    token: string,
    fingerprint: string
  ): Observable<SuccessResponse> {
    return this.#apiService.postValidateVisitorToken({
      _locale: this.#languageStore.currentLanguageCode(),
      body: {
        visitor_token: token,
        fingerprint: fingerprint
      }
    });
  }
}
